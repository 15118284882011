<template>
  <div class="full-width-padding">
    <div style="display: flex" class="full-width-padding">
      <p class="text-hoverable" @click="$router.go(-1)" style="display: flex; justify-content: flex-start; align-items: center">
        <BackIcon style="margin-right: 10px"/>Tillbaka
      </p>
    </div>
    <button class="auth" @click="createNewEvent">Skapa event</button>
    
    <calendar @callback="editEvent" />

    <vue-final-modal v-model="showModal" :lock-scroll="true" classes="admin-modal-container" content-class="admin-modal-content">
      <h1 style="margin-top: 0">Skapa Event</h1>
      <div style="display: flex; justify-content: space-between; align-items: center">
        <h3 style="text-align: left">Text</h3>
        <div style="display: flex">
          <p class="auth text-hoverable" :style="{color: !english ? '#269400' : 'black', fontWeight: !english ? 'bold' : 'normal'}" style="margin-right: 10px" @click="english = false">Svenska</p>
          <p class="auth text-hoverable" :style="{color: english ? '#269400' : 'black', fontWeight: english ? 'bold' : 'inherit'}" @click="english = true">Engelska</p>
        </div>
      </div>
      <textarea class="auth" type="text" style="max-width: 500px; min-height: 100px; max-height: 300px;" v-model="text[+english]">
      </textarea>
      <h3 style="text-align: left">Datum</h3>
      <v-date-picker :locale="$i18n.locale" v-model="date" :first-day-of-week="2" mode="dateTime" style="margin: 20px auto; text-transform: capitalize" is24hr/>
      <p class="auth text--warning" style="text-align: center">{{warningText}}</p>
      <div style="display: flex; justify-content: center; align-items: center">
        <p v-if="editIndex != -1" class="auth text--warning text-hoverable" @click="deleteEvent">Ta bort</p>
        <button class="auth" style="position: relative; margin-bottom: 20px; background-color: #269400" @click="publish">
          <div v-if="publishLoading" class="auth-loader" style="position: absolute; left: 15px; top: 12px"></div>
          Publicera
        </button>
      </div> 
    </vue-final-modal>
  </div>
</template>

<script>
import BackIcon from '../../assets/icons/back.svg'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import moment from 'moment'
import Calendar from '../../components/Calendar.vue'

export default {
  data() {
    return {
      editIndex: -1,
      showModal: false,
      date: new Date(),
      text: ['', ''],
      english: false,
      publishLoading: false,
      warningText: '',
    }
  },
  metaInfo() {
    return {
      title: 'Admin - Kalender'
    }
  },
  methods: {
    async publish() {
      this.publishLoading = true

      // if(!(this.text[0] && this.text[1])) {
      //   this.warningText = 'Allt är inte ifyllt!'
      //   this.publishLoading = false
      //   return
      // }

      console.log('ahjwdiojoiwad')
      await this.$store.dispatch('publishEvent', { textSwe: this.text[0], textEng: this.text[1], date: moment(this.date).format(), editIndex: this.editIndex })
      this.publishLoading = false
      this.showModal = false

      this.date = new Date()
      this.text = ['', '']
      this.warningText = ''
    },
    async deleteEvent() {
      await this.$store.dispatch('deleteEvent', { editIndex: this.editIndex })
    },
    createNewEvent() {
      this.showModal = true
      this.text = ['', '']
      this.date = new Date()
      this.editIndex = -1
    },
    editEvent(event) {
      const index = this.$store.state.events.indexOf(event)
      this.showModal = true
      this.text[0] = event.textSwe
      this.text[1] = event.textEng
      this.date = event.date
      this.editIndex = index
    }
  },
  computed: {
    currentText() {
      return this.english ? this.textEng : this.textSwe
    }
  },
  components: {
    BackIcon,
    VueFinalModal, 
    ModalsContainer,
    Calendar
  }
}
</script>

<style>
.admin-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-modal-content {
  display: flex;
  flex-direction: column;
  
  width: auto; 
  min-height: 200px; 
  background-color: white; 
  margin: auto 0;
  padding: 30px;
}

</style>